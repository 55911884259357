import Interceptor from "@/services/Interceptor";
import Settings from "@/config";
import { getParams } from "@/services/api/utils";

const PROJECT_URI = Settings.API_URI_PROJECT_V2;

export const getContactsV2 = (project_id) =>
  Interceptor({
    method: "GET",
    url: `${PROJECT_URI}/contacts`,
    params: getParams({ project_id }),
  });

export const getContactsCountV2 = (project_id) =>
  Interceptor({
    method: "GET",
    url: `${PROJECT_URI}/contacts/count`,
    params: getParams({ project_id }),
  });

/**
 * <strong>Allowed filters are:</strong><br />
 * <ul>
 *     <li>status: string = undefined</li>
 *     <li>is_doctor: boolean = undefined</li>
 *     <li>label: string = undefined</li>
 *     <li>postal_code: string = undefined</li>
 *     <li>status: string = undefined</li>
 *     <li>city: string = undefined</li>
 *     <li>professional_group: string = undefined</li>
 *     <li>speciality: string = undefined</li>
 *     <li>institution: string = undefined</li>
 * <ul>
 */
export const getContactsCountWithFilter = (project_id, filters) =>
  Interceptor({
    method: "POST",
    url: `${PROJECT_URI}/contacts/total`,
    params: getParams({ project_id }),
    data: filters,
  });

/**
 * <strong>Allowed filters are:</strong><br />
 * <ul>
 *     <li>status: string = undefined</li>
 *     <li>is_doctor: boolean = undefined</li>
 *     <li>label: string = undefined</li>
 *     <li>postal_code: string = undefined</li>
 *     <li>city: string = undefined</li>
 *     <li>professional_group: string = undefined</li>
 *     <li>speciality: string = undefined</li>
 *     <li>institution: string = undefined</li>
 * <ul>
 *
 * <strong>Offset = pages and limit = count per page</strong>
 */
export const getContactsWithFilter = (project_id, filters, limit, offset) =>
  Interceptor({
    method: "POST",
    url: `${PROJECT_URI}/contacts/all`,
    params: getParams({ project_id, limit, offset }),
    data: filters,
  });
