<template>
  <md-dialog :md-active="showCreateDialog">
    <md-dialog-title>Neuen Kontakt erstellen</md-dialog-title>

    <md-dialog-content style="width: 600px">
      <!-- OneKey-ID -->
      <md-field>
        <label>OneKey-ID</label>
        <md-input type="text" v-model="one_key_id" required />
      </md-field>

      <!-- ref_id -->
      <md-field>
        <label>Referenz-ID</label>
        <md-input type="text" v-model="ref_id" required />
      </md-field>

      <!-- Anrede -->
      <md-field>
        <label>Anrede</label>
        <md-input type="text" v-model="salutation" />
        <span class="md-helper-text">Dieses Feld ist optional</span>
      </md-field>

      <!-- Title -->
      <md-field>
        <label>Title</label>
        <md-input type="text" v-model="title" />
        <span class="md-helper-text">Dieses Feld ist optional</span>
      </md-field>

      <!-- first_name -->
      <md-field>
        <label>Vorname</label>
        <md-input type="text" v-model="first_name" required />
      </md-field>

      <!-- last_name -->
      <md-field>
        <label>Nachname</label>
        <md-input type="text" v-model="last_name" required />
      </md-field>

      <!-- phone_number -->
      <md-field>
        <label>Telefonnummer</label>
        <md-input type="text" v-model="phone_number" required />
      </md-field>

      <!-- is_doctor -->
      <md-field>
        <label>Art des Kontakts</label>
        <md-select v-model="is_doctor" required>
          <md-option :value="true">Doktor</md-option>
          <md-option :value="false">Fachangestellter</md-option>
        </md-select>
      </md-field>

      <!-- professional group -->
      <md-field>
        <label>Fachgruppe</label>
        <md-input type="text" v-model="professional_group" required />
      </md-field>

      <!-- speciality -->
      <md-field>
        <label>Fachrichtung</label>
        <md-input type="text" v-model="speciality" />
        <span class="md-helper-text">Dieses Feld ist optional</span>
      </md-field>

      <!-- institution -->
      <md-field>
        <label>Institution</label>
        <md-input type="text" v-model="institution" />
        <span class="md-helper-text">Dieses Feld ist optional</span>
      </md-field>

      <!-- mail -->
      <md-field>
        <label>E-Mail-Adresse</label>
        <md-input type="text" v-model="mail" />
        <span class="md-helper-text">Dieses Feld ist optional</span>
      </md-field>

      <!-- website_url -->
      <md-field>
        <label>Website-URL</label>
        <md-input type="text" v-model="website_url" />
        <span class="md-helper-text">Dieses Feld ist optional</span>
      </md-field>

      <!-- address -->
      <md-field>
        <label>Straße und Hausnummer</label>
        <md-input type="text" v-model="address" />
        <span class="md-helper-text">Dieses Feld ist optional</span>
      </md-field>

      <!-- address -->
      <md-field>
        <label>Ort</label>
        <md-input type="text" v-model="city" />
        <span class="md-helper-text">Dieses Feld ist optional</span>
      </md-field>

      <!-- address -->
      <md-field>
        <label>Postleitzahl</label>
        <md-input type="text" v-model="postal_code" />
        <span class="md-helper-text">Dieses Feld ist optional</span>
      </md-field>

      <!-- Geography -->
      <md-field>
        <label>Geographie</label>
        <md-input type="text" v-model="geography" />
        <span class="md-helper-text">Dieses Feld ist optional</span>
      </md-field>

      <!-- Region -->
      <md-field>
        <label>Region</label>
        <md-input type="text" v-model="region" />
        <span class="md-helper-text">Dieses Feld ist optional</span>
      </md-field>

      <!-- Segment -->
      <md-field>
        <label>Segment</label>
        <md-input type="text" v-model="segment" />
        <span class="md-helper-text">Dieses Feld ist optional</span>
      </md-field>

      <!-- label -->
      <md-field>
        <label>Zuordnung</label>
        <md-input type="text" v-model="label" />
      </md-field>
    </md-dialog-content>

    <md-dialog-actions>
      <md-button class="md-accent" @click="emitCloseDialog"
        >Abbrechen</md-button
      >
      <md-button class="md-primary" @click="createNewContact"
        >Kontakt erstellen</md-button
      >
    </md-dialog-actions>
  </md-dialog>
</template>

<script>
import { createContact } from "@/services/api/contact";

export default {
  name: "createContactDialog",
  props: {
    project_id: {
      type: String,
      required: true,
    },
    showCreateDialog: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      salutation: null,
      first_name: null,
      last_name: null,
      ref_id: null,
      title: null,
      phone_number: null,
      is_doctor: true,
      label: null,
      mail: null,
      one_key_id: null,
      speciality: null,
      address: null,
      city: null,
      postal_code: null,
      website_url: null,
      professional_group: null,
      institution: null,
      geography: null,
      region: null,
      segment: null,
    };
  },
  methods: {
    async createNewContact() {
      try {
        if (!this.professional_group) {
          throw new Error();
        }
        let response = await createContact(this.project_id, this.$data);
        this.emitSuccess(response);
        this.emitCloseDialog();
      } catch (error) {
        this.emitError();
        this.emitCloseDialog();
      }
    },
    emitCloseDialog() {
      this.$emit("closeDialog");
      this.reset();
    },
    emitError() {
      this.$emit("error", "There has been an error creating this contact.");
    },
    emitSuccess(data) {
      this.$emit("success", {
        msg: `${data.first_name} ${data.last_name} has been successfully created.`,
        data: data,
      });
    },
    reset() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
  },
};
</script>

<style lang="scss" scoped></style>
