<template>
  <iq-main>
    <iq-loader :loading="isPageLoading || isExportingData" />

    <!-- Content -->
    <div class="container" v-if="!isPageLoading">
      <!-- Toolbar -->
      <iq-toolbar is-with-center>
        <template v-slot:left>
          <md-button
            class="md-primary md-icon-button margin-right-1"
            @click="redirect({ name: 'Project', params: { id: projectId } })"
          >
            <md-icon>arrow_back</md-icon>
          </md-button>

          <div class="md-title">{{ project.name }}</div>
        </template>

        <template v-slot:right>
          <md-button
            class="md-icon-button md-primary margin-right-1"
            @click="exportAllContacts"
          >
            <md-icon>cloud_download</md-icon>
            <md-tooltip md-direction="left">Kontakte exportieren</md-tooltip>
          </md-button>

          <md-button
            class="md-icon-button md-primary margin-right-1"
            @click="toggleFiltersDialog"
          >
            <md-icon>filter_alt</md-icon>
            <md-tooltip md-direction="left">Kontakte filtern</md-tooltip>
          </md-button>

          <md-button
            class="md-icon-button md-primary margin-right-1"
            @click="toggleColumnsDialog"
          >
            <md-icon>table_chart</md-icon>
            <md-tooltip md-direction="left">Tabelle anpassen</md-tooltip>
          </md-button>

          <md-button
            class="md-icon-button md-primary margin-right-0"
            @click="toggleContactCreateDialog"
            v-if="isComponentActive(['admin'])"
          >
            <md-icon>person_add</md-icon>
            <md-tooltip md-direction="left">Kontakte erstellen</md-tooltip>
          </md-button>

          <md-button
            class="md-icon-button md-primary margin-right-0"
            @click="toggleUploadDialog"
            v-if="isComponentActive(['admin'])"
          >
            <md-icon>add_circle</md-icon>
            <md-tooltip md-direction="left">Kontakte hochladen</md-tooltip>
          </md-button>
        </template>
      </iq-toolbar>

      <iq-toolbar
        v-if="contacts.selected.length > 0"
        style="
          position: absolute;
          top: 60px;
          left: 0;
          right: 0;
          height: 74px;
          z-index: 5;
        "
      >
        <template v-slot:left>
          {{ contacts.selected.length }} Kontakte ausgewählt
        </template>

        <template v-slot:right>
          <!--<md-button @click="toggleStatusDialog">Versandstatus ändern</md-button>-->
          <md-button @click="toggleAttributeDialog"
            >Attribut hinzufügen</md-button
          >
          <md-button @click="toggleStatusDialog">Status ändern</md-button>
          <md-button @click="toggleLabelDialog">Kontakt zuordnen</md-button>
          <md-button @click="handleAssignAsJointPractice"
            >Gemeinschaftspraxis markieren</md-button
          >

          <md-button
            class="md-accent"
            @click="handleDeleteContacts"
            v-if="isComponentActive(['admin'])"
            >Kontakt löschen</md-button
          >
        </template>
      </iq-toolbar>

      <!-- Table -->
      <div style="padding: 1rem">
        <md-card>
          <div class="content-container">
            <md-table
              v-model="contacts.searched"
              style="position: relative"
              :md-sort.sync="table.currentSort"
              :md-sort-order.sync="table.currentSortOrder"
              :md-sort-fn="customSortFn"
              @md-selected="handleTableSelection"
            >
              <md-table-toolbar>
                <div class="md-toolbar-section-start" style="margin-top: 6px">
                  <md-chip
                    :md-deletable="filters.name !== 'all'"
                    @md-delete="handleResetFilter('name')"
                  >
                    <!--                  <md-icon style="font-size: 18px !important">filter_alt</md-icon>-->
                    Name:
                    <strong>{{
                      !filters.name || filters.name === "all"
                        ? "Alle"
                        : filters.name
                    }}</strong>
                  </md-chip>

                  <md-chip
                    :md-deletable="filters.ref_id !== 'all'"
                    @md-delete="handleResetFilter('ref_id')"
                  >
                    <!--                  <md-icon style="font-size: 18px !important">filter_alt</md-icon>-->
                    Referenz-ID:
                    <strong>{{
                      !filters.ref_id || filters.ref_id === "all"
                        ? "Alle"
                        : filters.ref_id
                    }}</strong>
                  </md-chip>

                  <md-chip
                    :md-deletable="filters.status !== 'all'"
                    @md-delete="handleResetFilter('status')"
                  >
                    <!--                  <md-icon style="font-size: 18px !important">filter_alt</md-icon>-->
                    Status:
                    <strong>{{
                      !filters.status || filters.status === "all"
                        ? "Alle"
                        : filters.status
                    }}</strong>
                  </md-chip>

                  <!--<md-chip
                    :md-deletable="filters.shipping_status !== 'all'"
                    @md-delete="handleResetFilter('shipping_status')"
                  >
                                      <md-icon style="font-size: 18px !important">filter_alt</md-icon>
                    Versandstatus:
                    <strong>{{
                      !filters.shipping_status || filters.shipping_status === "all"
                        ? "Alle"
                        : filters.shipping_status
                    }}</strong>
                  </md-chip>-->

                  <md-chip
                    :md-deletable="filters.phone_number !== 'all'"
                    @md-delete="handleResetFilter('phone_number')"
                  >
                    <!--                  <md-icon style="font-size: 18px !important">filter_alt</md-icon>-->
                    Telefonnummer:
                    <strong>{{
                      !filters.phone_number || filters.phone_number === "all"
                        ? "Alle"
                        : filters.phone_number
                    }}</strong>
                  </md-chip>

                  <md-chip
                    :md-deletable="filters.is_doctor !== 'all'"
                    @md-delete="handleResetFilter('is_doctor')"
                  >
                    <!--                  <md-icon style="font-size: 18px !important">filter_alt</md-icon>-->
                    Typ:
                    <strong>{{
                      !filters.is_doctor || filters.is_doctor === "all"
                        ? "Alle"
                        : filters.is_doctor
                    }}</strong>
                  </md-chip>

                  <md-chip
                    :md-deletable="filters.label !== 'all'"
                    @md-delete="handleResetFilter('label')"
                  >
                    <!--                  <md-icon style="font-size: 18px !important">filter_alt</md-icon>-->
                    Zuordnung:
                    <strong>{{
                      !filters.label || filters.label === "all"
                        ? "Alle"
                        : filters.label
                    }}</strong>
                  </md-chip>

                  <md-chip
                    :md-deletable="filters.postal_code !== 'all'"
                    @md-delete="handleResetFilter('postal_code')"
                  >
                    <!--                  <md-icon style="font-size: 18px !important">filter_alt</md-icon>-->
                    Postleitzahl:
                    <strong>{{
                      !filters.postal_code || filters.postal_code === "all"
                        ? "Alle"
                        : filters.postal_code
                    }}</strong>
                  </md-chip>

                  <md-chip
                    :md-deletable="filters.city !== 'all'"
                    @md-delete="handleResetFilter('city')"
                  >
                    <!--                  <md-icon style="font-size: 18px !important">filter_alt</md-icon>-->
                    Stadt:
                    <strong>{{
                      !filters.city || filters.city === "all"
                        ? "Alle"
                        : filters.city
                    }}</strong>
                  </md-chip>

                  <md-chip
                    :md-deletable="filters.professional_group !== 'all'"
                    @md-delete="handleResetFilter('professional_group')"
                  >
                    <!--                  <md-icon style="font-size: 18px !important">filter_alt</md-icon>-->
                    Fachgruppe:
                    <strong>{{
                      !filters.professional_group ||
                      filters.professional_group === "all"
                        ? "Alle"
                        : filters.professional_group
                    }}</strong>
                  </md-chip>

                  <md-chip
                    :md-deletable="filters.specialty !== 'all'"
                    @md-delete="handleResetFilter('specialty')"
                  >
                    <!--                  <md-icon style="font-size: 18px !important">filter_alt</md-icon>-->
                    Fachrichtung:
                    <strong>{{
                      !filters.specialty || filters.specialty === "all"
                        ? "Alle"
                        : filters.specialty
                    }}</strong>
                  </md-chip>

                  <md-chip
                    :md-deletable="filters.institution !== 'all'"
                    @md-delete="handleResetFilter('institution')"
                  >
                    <!--                  <md-icon style="font-size: 18px !important">filter_alt</md-icon>-->
                    Institution:
                    <strong>{{
                      !filters.institution || filters.institution === "all"
                        ? "Alle"
                        : filters.institution
                    }}</strong>
                  </md-chip>

                  <md-chip
                    :md-deletable="
                      filters.first_contact_from !== '' &&
                      filters.first_contact_to !== ''
                    "
                    @md-delete="handleResetFilter('first_contact')"
                  >
                    <!--                  <md-icon style="font-size: 18px !important">filter_alt</md-icon>-->
                    Erster Kontakt:
                    <strong>{{
                      filters.first_contact_from === "" ||
                      filters.first_contact_to === ""
                        ? "Alle"
                        : `${filters.first_contact_from} - ${filters.first_contact_to}`
                    }}</strong>
                  </md-chip>

                  <md-chip
                    :md-deletable="
                      filters.last_contact_from !== '' &&
                      filters.last_contact_to !== ''
                    "
                    @md-delete="handleResetFilter('last_contact')"
                  >
                    <!--                  <md-icon style="font-size: 18px !important">filter_alt</md-icon>-->
                    Letzter Kontakt:
                    <strong>{{
                      filters.last_contact_from === "" ||
                      filters.last_contact_to === ""
                        ? "Alle"
                        : `${filters.last_contact_from} - ${filters.last_contact_to}`
                    }}</strong>
                  </md-chip>

                  <md-chip
                    :md-deletable="filters.has_open_tasks"
                    @md-delete="handleResetFilter('has_open_tasks')"
                  >
                    <!--                  <md-icon style="font-size: 18px !important">filter_alt</md-icon>-->
                    Offene Aufgaben:
                    <strong>{{
                      filters.has_open_tasks ? "Nur offene" : "Alle"
                    }}</strong>
                  </md-chip>
                </div>
              </md-table-toolbar>

<!--              <md-table-empty-state md-label="Keine Kontakte gefunden" />-->

              <md-table-row
                slot="md-table-row"
                slot-scope="{ item }"
                md-selectable="multiple"
                md-auto-select
              >
                <md-table-cell
                  md-label="OneKey-ID"
                  md-sort-by="one_key_id"
                  v-if="table.columns.isOneKeyIdVisible"
                >
                  {{ item.one_key_id | emptyValue }}
                </md-table-cell>

                <md-table-cell
                  md-label="Referenz-ID"
                  md-sort-by="ref_id"
                  v-if="table.columns.isRefIdVisible"
                >
                  {{ item.ref_id | emptyValue }}
                </md-table-cell>

                <md-table-cell
                  md-label="Anrede"
                  md-sort-by="salutation"
                  v-if="table.columns.isSalutationVisible"
                >
                  {{ item.salutation | emptyValue }}
                </md-table-cell>

                <md-table-cell
                  md-label="Titel"
                  md-sort-by="title"
                  v-if="table.columns.isTitleVisible"
                >
                  {{ item.title | emptyValue }}
                </md-table-cell>

                <md-table-cell
                  md-label="Vorname"
                  md-sort-by="first_name"
                  v-if="table.columns.isFirstNameVisible"
                >
                  <a @click="handleRedirectRequest(item.id)">
                    {{ item.first_name | emptyValue }}
                  </a>
                </md-table-cell>

                <md-table-cell
                  md-label="Nachname"
                  md-sort-by="last_name"
                  v-if="table.columns.isLastNameVisible"
                >
                  <a @click="handleRedirectRequest(item.id)">
                    {{ item.last_name | emptyValue }}
                  </a>
                </md-table-cell>

                <md-table-cell
                  md-label="Status"
                  md-sort-by="status"
                  v-if="table.columns.isStatusVisible"
                >
                  <iq-status-pill :status="item.status" />
                </md-table-cell>

                <md-table-cell
                  md-label="Typ"
                  v-if="table.columns.isTypeVisible"
                >
                  <span class="iq-pill iq-pill-blue" v-if="item.is_doctor"
                    >Doktor</span
                  >
                  <span class="iq-pill iq-pill-green" v-else
                    >Fachangestellter</span
                  >
                </md-table-cell>

                <md-table-cell
                  md-label="Fachgruppe"
                  md-sort-by="professional_group"
                  v-if="table.columns.isProfessionalGroupVisible"
                >
                  {{ item.professional_group | emptyValue }}
                </md-table-cell>

                <md-table-cell
                  md-label="Fachrichtung"
                  md-sort-by="speciality"
                  v-if="table.columns.isSpecialityVisible"
                >
                  {{ item.speciality | emptyValue }}
                </md-table-cell>

                <md-table-cell
                  md-label="Institution"
                  md-sort-by="institution"
                  v-if="table.columns.isInstitutionVisible"
                >
                  {{ item.institution | emptyValue }}
                </md-table-cell>

                <md-table-cell
                  md-label="Telefonnummer"
                  md-sort-by="phone_number"
                  v-if="table.columns.isPhoneNumberVisible"
                >
                  {{ item.phone_number | emptyValue }}
                </md-table-cell>

                <md-table-cell
                  md-label="E-Mail-Adresse"
                  md-sort-by="mail"
                  v-if="table.columns.isMailVisible"
                >
                  {{ item.mail | emptyValue }}
                </md-table-cell>

                <md-table-cell
                  md-label="Faxnummer"
                  md-sort-by="fax_number"
                  v-if="table.columns.isFaxNumberVisible"
                >
                  {{ item.fax_number | emptyValue }}
                </md-table-cell>

                <md-table-cell
                  md-label="Postleitzahl"
                  md-sort-by="postal_code"
                  v-if="table.columns.isPostalCodeVisible"
                >
                  {{ item.postal_code | emptyValue }}
                </md-table-cell>

                <md-table-cell
                  md-label="Stadt"
                  md-sort-by="city"
                  v-if="table.columns.isCityVisible"
                >
                  {{ item.city | emptyValue }}
                </md-table-cell>

                <md-table-cell
                  md-label="Geographie"
                  md-sort-by="geography"
                  v-if="table.columns.isGeographyVisible"
                >
                  {{ item.geography | emptyValue }}
                </md-table-cell>

                <md-table-cell
                  md-label="Region"
                  md-sort-by="region"
                  v-if="table.columns.isRegionVisible"
                >
                  {{ item.region | emptyValue }}
                </md-table-cell>

                <md-table-cell
                  md-label="Segment"
                  md-sort-by="segment"
                  v-if="table.columns.isSegmentVisible"
                >
                  {{ item.segment | emptyValue }}
                </md-table-cell>

                <md-table-cell
                  md-label="Gemeinschaftspraxis"
                  md-sort-by="joint_practice"
                  v-if="table.columns.isJointPracticeVisible"
                >
                  {{ item.joint_practice | formatJointPractice }}
                </md-table-cell>

                <!--<md-table-cell
                  md-label="Versandstatus"
                  md-sort-by="shipping_status"
                  v-if="table.columns.isShippingStatusVisible"
                >
                  <iq-status-pill :shipping_status="item.shipping_status" />
                </md-table-cell>-->

                <md-table-cell
                  md-label="Zuordnung"
                  md-sort-by="label"
                  v-if="table.columns.isLabelVisible"
                >
                  {{ getUserName(item.label) }}
                </md-table-cell>

                <md-table-cell
                  md-label="Bearbeitet von"
                  md-sort-by="edited_by"
                  v-if="table.columns.isEditedByVisible"
                >
                  {{ item.edited_by | emptyValue }}
                </md-table-cell>

                <md-table-cell
                  md-label="Erster Kontakt"
                  md-sort-by="first_contact"
                  v-if="table.columns.isFirstContactVisible"
                >
                  {{ item.first_contact | formatDate }}
                </md-table-cell>

                <md-table-cell
                  md-label="Letzter Kontakt"
                  md-sort-by="last_contact"
                  v-if="table.columns.isLastContactVisible"
                >
                  {{ item.last_contact | formatDate }}
                </md-table-cell>

                <md-table-cell
                  md-label="Offene Aufgaben"
                  v-if="table.columns.isOpenTasksVisible"
                >
                  {{ item.task_count | formatTasks }}
                </md-table-cell>

                <md-table-cell
                  md-label="Attribute"
                  v-if="table.columns.isAttributesVisible"
                >
                  <span v-if="item.attributes && !item.attributes.length"
                    >Keine Attribute</span
                  >
                  <span
                    class="iq-pill iq-pill-gray"
                    style="margin-right: 4px"
                    v-for="attr in item.attributes"
                    :key="attr.id"
                  >
                    {{
                      `${attr.attribute_key}: ${resolveAttributeValue(
                        attr.attribute_value
                      )}`
                    }}
                  </span>
                </md-table-cell>

                <md-table-cell md-label="Aktionen">
                  <md-button
                    class="md-icon-button md-primary"
                    @click="handleRedirectRequest(item.id)"
                  >
                    <md-icon>visibility</md-icon>
                  </md-button>
                </md-table-cell>
              </md-table-row>
            </md-table>

            <iq-empty-state
              v-if="contacts.count === 0"
              title="Keine Kontakte gefunden"
            />

            <md-divider />

            <div class="table-pagination">
              <span style="margin-right: 1rem; max-width: 150px">
                <md-button @click="togglePageDialog"> Gehe zu Seite </md-button>
              </span>

              <span>
                <strong>{{ contacts.count }}</strong> Kontakte insgesamt -
                <strong>{{ table.limit }}</strong> Kontakte pro Seite - Seite
                <strong>{{ table.offset + 1 }}</strong> von
                <strong>{{ getTotalPagesCount() }}</strong>
              </span>

              <md-button
                class="md-icon-button table-pagination-previous"
                :disabled="table.offset === 0"
                @click="handlePageDown"
              >
                <md-icon>keyboard_arrow_left</md-icon>
              </md-button>

              <md-button
                class="md-icon-button"
                :md-disabled="table.offset === getTotalPagesCount()"
                @click="handlePageUp"
              >
                <md-icon>keyboard_arrow_right</md-icon>
              </md-button>
            </div>
          </div>
        </md-card>
      </div>

      <!-- Snackbars -->
      <md-snackbar
        class="snackbar-adjuster snackbar-adjuster--error"
        :md-active.sync="isError"
        >{{ errorMessage }}
      </md-snackbar>
      <md-snackbar
        class="snackbar-adjuster snackbar-adjuster--success"
        :md-active.sync="isSuccess"
        >{{ successMessage }}
      </md-snackbar>

      <!-- Dialogs -->
      <md-dialog :md-active.sync="dialogs.pages.active">
        <md-dialog-title>Seite auswählen</md-dialog-title>
        <md-dialog-content style="width: 500px">
          <p class="md-caption">Bitte wählen Sie eine Seite aus</p>
          <md-autocomplete
            v-model="dialogs.pages.selectedPage"
            :md-options="table.pages"
          >
            <label>Seite wählen</label>
          </md-autocomplete>
        </md-dialog-content>
        <md-dialog-actions>
          <md-button @click="togglePageDialog">Abbrechen</md-button>
          <md-button class="md-primary" @click="handleSelectPage"
            >Seite auswählen</md-button
          >
        </md-dialog-actions>
      </md-dialog>

      <md-dialog :md-active.sync="dialogs.status.active">
        <md-dialog-title>Status ändern</md-dialog-title>
        <md-dialog-content style="width: 500px">
          <p class="md-caption">Bitte wählen Sie den Status aus</p>
          <md-field>
            <label>Status wählen</label>
            <md-select v-model="dialogs.status.selectedStatus">
              <md-option value="backlog">Nicht bearbeitet</md-option>
              <md-option value="on_hold">Pausiert</md-option>
              <md-option value="in_progress">In Bearbeitung</md-option>
              <md-option value="in_review">In Überprüfung</md-option>
              <md-option value="retired">Ausgeschieden</md-option>
              <md-option value="finished" v-if="isComponentActive(['admin'])"
                >Abgeschlossen</md-option
              >
            </md-select>
          </md-field>
        </md-dialog-content>
        <md-dialog-actions>
          <md-button @click="toggleStatusDialog">Abbrechen</md-button>
          <md-button class="md-primary" @click="handleChangeStatus"
            >Status ändern</md-button
          >
        </md-dialog-actions>
      </md-dialog>

      <!--<md-dialog :md-active.sync="dialogs.shippingStatus.active">
        <md-dialog-title>Versandstatus ändern</md-dialog-title>
        <md-dialog-content style="width: 500px">
          <p class="md-caption">Bitte wählen Sie den Versandstatus aus</p>
          <md-field>
            <label>Versandstatus wählen</label>
            <md-select v-model="dialogs.shippingStatus.selectedShippingStatus">
              <md-option value="backlog">Nicht bearbeitet</md-option>
              <md-option value="by_mail">Per Email versendet</md-option>
              <md-option value="by_fax">Per Fax versendet</md-option>
              <md-option value="by_post">Per Post versendet</md-option>
              <md-option value="not_required">Kein Versand notwendig</md-option>
              <md-option value="finished" v-if="isComponentActive(['admin'])"
                >Abgeschlossen</md-option
              > 
            </md-select>
          </md-field>
        </md-dialog-content>
        <md-dialog-actions>
          <md-button @click="toggleShippingStatusDialog">Abbrechen</md-button>
          <md-button class="md-primary" @click="handleChangeShippingStatus"
            >Versandstatus ändern</md-button
          >
        </md-dialog-actions>
      </md-dialog>-->

      <md-dialog :md-active="dialogs.attribute.active">
        <md-dialog-title>Neues Attribut hinzufügen</md-dialog-title>

        <md-dialog-content style="min-width: 750px">
          <md-field md-clearable>
            <md-icon>data_object</md-icon>
            <label>Name des Attributs</label>
            <md-input maxlength="30" required v-model="dialogs.attribute.key" />
            <span class="md-helper-text"
              >Bitte geben Sie hier den Namen des Attributs an</span
            >
          </md-field>

          <md-field>
            <md-icon style="margin-right: 12px">data_object</md-icon>
            <label>Typ des Attributs</label>
            <md-select required v-model="dialogs.attribute.type">
              <md-option value="string">Text</md-option>
              <md-option value="int">Zahl</md-option>
              <md-option value="choice">Auswahl</md-option>
            </md-select>
            <span class="md-helper-text"
              >Bitte geben Sie hier den Typ des Attributs an</span
            >
          </md-field>

          <md-field
            md-clearable
            v-if="['string', 'int'].includes(dialogs.attribute.type)"
          >
            <md-icon>data_object</md-icon>
            <label>Wert des Attributs</label>
            <md-input
              maxlength="40"
              required
              v-model="dialogs.attribute.value"
            />
            <span class="md-helper-text"
              >Bitte geben Sie hier den Wert des Attributs an</span
            >
          </md-field>

          <md-field v-else>
            <md-icon>data_object</md-icon>
            <label>Wert des Attributs</label>
            <md-select
              v-model="dialogs.attribute.value"
              style="margin-left: 10px"
            >
              <md-option value="yes">Ja</md-option>
              <md-option value="no">Nein</md-option>
            </md-select>
            <span class="md-helper-text"
              >Bitte geben Sie hier den Wert des Attributs an</span
            >
          </md-field>
        </md-dialog-content>

        <md-dialog-actions>
          <md-button @click="toggleAttributeDialog">Abbrechen</md-button>
          <md-button class="md-primary" @click="handleCreateAttribute"
            >Speichern</md-button
          >
        </md-dialog-actions>
      </md-dialog>

      <md-dialog :md-active.sync="dialogs.label.active">
        <md-dialog-title>Kontakte zuordnen</md-dialog-title>
        <md-dialog-content style="width: 650px">
          <p class="md-caption">Bitte wählen Sie einen Benutzer aus</p>
          <md-field>
            <label>Benutzer wählen</label>
            <md-select v-model="dialogs.label.userId">
              <md-option v-for="user in users" :key="user.id" :value="user.id">
                {{ user.first_name }} {{ user.last_name }}
              </md-option>
            </md-select>
          </md-field>
        </md-dialog-content>
        <md-dialog-actions>
          <md-button
            class="md-accent"
            style="margin-right: auto"
            @click="handleUnassignContact"
            >Zuordnung löschen</md-button
          >
          <md-button @click="toggleLabelDialog">Abbrechen</md-button>
          <md-button class="md-primary" @click="handleAssignContact"
            >Kontakte zuordnen</md-button
          >
        </md-dialog-actions>
      </md-dialog>

      <md-dialog :md-active="dialogs.columns.active">
        <md-dialog-title>Tabelle anpassen</md-dialog-title>

        <md-dialog-content style="width: 650px">
          <md-list>
            <md-list-item>
              <md-checkbox v-model="table.columns.isOneKeyIdVisible" />
              <span class="md-list-item-text">OneKey-ID anzeigen</span>
            </md-list-item>

            <md-divider class="md-inset" />

            <md-list-item>
              <md-checkbox v-model="table.columns.isRefIdVisible" />
              <span class="md-list-item-text">Referenz-ID anzeigen</span>
            </md-list-item>

            <md-divider class="md-inset" />

            <md-list-item>
              <md-checkbox v-model="table.columns.isSalutationVisible" />
              <span class="md-list-item-text">Anrede anzeigen</span>
            </md-list-item>

            <md-divider class="md-inset" />

            <md-list-item>
              <md-checkbox v-model="table.columns.isTitleVisible" />
              <span class="md-list-item-text">Titel anzeigen</span>
            </md-list-item>

            <md-divider class="md-inset" />

            <md-list-item>
              <md-checkbox v-model="table.columns.isFirstNameVisible" />
              <span class="md-list-item-text">Vorname anzeigen</span>
            </md-list-item>

            <md-divider class="md-inset" />

            <md-list-item>
              <md-checkbox v-model="table.columns.isLastNameVisible" />
              <span class="md-list-item-text">Nachname anzeigen</span>
            </md-list-item>

            <md-divider class="md-inset" />

            <!-- <md-list-item>
              <md-checkbox v-model="table.columns.isFullNameVisible" />
              <span class="md-list-item-text">Vor- & Nachname anzeigen</span>
            </md-list-item>

            <md-divider class="md-inset" />  -->

            <md-list-item>
              <md-checkbox v-model="table.columns.isStatusVisible" />
              <span class="md-list-item-text">Status anzeigen</span>
            </md-list-item>

            <md-divider class="md-inset" />

            <md-list-item>
              <md-checkbox v-model="table.columns.isTypeVisible" />
              <span class="md-list-item-text">Typ anzeigen</span>
            </md-list-item>

            <md-divider class="md-inset" />

            <md-list-item>
              <md-checkbox v-model="table.columns.isPhoneNumberVisible" />
              <span class="md-list-item-text">Telefonnummer anzeigen</span>
            </md-list-item>

            <md-divider class="md-inset" />

            <md-list-item>
              <md-checkbox v-model="table.columns.isMailVisible" />
              <span class="md-list-item-text">Mail-Adresse anzeigen</span>
            </md-list-item>

            <md-divider class="md-inset" />

            <md-list-item>
              <md-checkbox v-model="table.columns.isFaxNumberVisible" />
              <span class="md-list-item-text">Faxnummer anzeigen</span>
            </md-list-item>

            <md-divider class="md-inset" />

            <md-list-item>
              <md-checkbox v-model="table.columns.isPostalCodeVisible" />
              <span class="md-list-item-text">Postleitzahl anzeigen</span>
            </md-list-item>

            <md-divider class="md-inset" />

            <md-list-item>
              <md-checkbox v-model="table.columns.isCityVisible" />
              <span class="md-list-item-text">Stadt anzeigen</span>
            </md-list-item>

            <md-divider class="md-inset" />

            <md-list-item>
              <md-checkbox v-model="table.columns.isProfessionalGroupVisible" />
              <span class="md-list-item-text">Fachgruppe anzeigen</span>
            </md-list-item>

            <md-divider class="md-inset" />

            <md-list-item>
              <md-checkbox v-model="table.columns.isSpecialityVisible" />
              <span class="md-list-item-text">Fachrichtung anzeigen</span>
            </md-list-item>

            <md-divider class="md-inset" />

            <md-list-item>
              <md-checkbox v-model="table.columns.isInstitutionVisible" />
              <span class="md-list-item-text">Institution anzeigen</span>
            </md-list-item>

            <md-divider class="md-inset" />

            <md-list-item>
              <md-checkbox v-model="table.columns.isJointPracticeVisible" />
              <span class="md-list-item-text"
                >Gemeinschaftspraxis anzeigen</span
              >
            </md-list-item>

            <md-divider class="md-inset" />

            <md-list-item>
              <md-checkbox v-model="table.columns.isLabelVisible" />
              <span class="md-list-item-text">Zuordnung anzeigen</span>
            </md-list-item>

            <md-divider class="md-inset" />

            <md-list-item>
              <md-checkbox v-model="table.columns.isEditedByVisible" />
              <span class="md-list-item-text">Bearbeitet von anzeigen</span>
            </md-list-item>

            <md-divider class="md-inset" />

            <md-list-item>
              <md-checkbox v-model="table.columns.isFirstContactVisible" />
              <span class="md-list-item-text">Erster Kontakt anzeigen</span>
            </md-list-item>

            <md-divider class="md-inset" />

            <md-list-item>
              <md-checkbox v-model="table.columns.isLastContactVisible" />
              <span class="md-list-item-text">Letzter Kontakt anzeigen</span>
            </md-list-item>

            <md-divider class="md-inset" />

            <md-list-item>
              <md-checkbox v-model="table.columns.isOpenTasksVisible" />
              <span class="md-list-item-text">Offene Aufgaben anzeigen</span>
            </md-list-item>

            <md-divider class="md-inset" />

            <md-list-item>
              <md-checkbox v-model="table.columns.isAttributesVisible" />
              <span class="md-list-item-text">Attribute anzeigen</span>
            </md-list-item>

            <md-divider class="md-inset" />

            <md-list-item>
              <md-checkbox v-model="table.columns.isGeographyVisible" />
              <span class="md-list-item-text">Geographie anzeigen</span>
            </md-list-item>

            <md-divider class="md-inset" />

            <md-list-item>
              <md-checkbox v-model="table.columns.isRegionVisible" />
              <span class="md-list-item-text">Region anzeigen</span>
            </md-list-item>

            <md-divider class="md-inset" />

            <md-list-item>
              <md-checkbox v-model="table.columns.isSegmentVisible" />
              <span class="md-list-item-text">Segment anzeigen</span>
            </md-list-item>

            <md-divider class="md-inset" />

            <!--<md-list-item>
              <md-checkbox v-model="table.columns.isShippingStatusVisible" />
              <span class="md-list-item-text">Versandstatus anzeigen</span>
            </md-list-item>

            <md-divider class="md-inset" />-->
          </md-list>
        </md-dialog-content>

        <md-dialog-actions>
          <md-button @click="toggleColumnsDialog">Schließen</md-button>
        </md-dialog-actions>
      </md-dialog>

      <md-dialog :md-active="dialogs.upload.active">
        <md-dialog-title>Kontakte hochladen</md-dialog-title>

        <md-dialog-content style="width: 750px">
          <!-- Loading state -->
          <md-progress-bar
            md-mode="indeterminate"
            v-if="dialogs.upload.loading"
          />
          <div style="height: 5px" v-else />

          <div
            class="upload-container"
            :class="dialogs.upload.loading && 'upload-container-disabled'"
          >
            <input
              type="file"
              ref="fileInput"
              class="hidden"
              @change="onUpload"
              accept=".xls,.xlsx"
              style="display: none"
            />

            <md-icon class="md-primary md-size-2x">upload</md-icon>

            <div>
              <md-button class="md-primary" @click="triggerFileUpload"
                >Hochladen</md-button
              >
            </div>

            <div>
              <div class="md-caption">{{ dialogs.upload.excel.name }}</div>
            </div>
          </div>

          <div class="explainer-container">
            <div class="md-subtitle" style="margin-bottom: 4px">
              Wichtige Informationen zum Upload
            </div>
            <div class="md-caption">
              Für den Upload ist wichtig, dass alle Pflichtfelder in der
              Excel-Liste eingetragen sind. Diese Felder sind:
            </div>
            <div style="margin-top: 4px">
              <md-chip class="chip-adjuster">ref_id</md-chip>
              <md-chip class="chip-adjuster">one_key_id</md-chip>
              <md-chip class="chip-adjuster">first_name</md-chip>
              <md-chip class="chip-adjuster">last_name</md-chip>
              <md-chip class="chip-adjuster">phone_number</md-chip>
            </div>
          </div>
        </md-dialog-content>

        <md-dialog-actions>
          <md-button class="md-accent" @click="toggleUploadDialog"
            >Abbrechen</md-button
          >
          <md-button
            class="md-primary"
            :disabled="!dialogs.upload.excel.result"
            @click="handleBulkUpload"
            >Hochladen</md-button
          >
        </md-dialog-actions>
      </md-dialog>

      <md-dialog :md-active="dialogs.filters.active">
        <md-dialog-title>Kontaktübersicht filtern</md-dialog-title>

        <md-dialog-content style="width: 900px">
          <md-field md-clearable>
            <label>Name des Kontakts</label>
            <md-input type="text" v-model="filters.name" />
          </md-field>

          <md-field md-clearable>
            <label>Referenz-ID des Kontakts</label>
            <md-input type="text" v-model="filters.ref_id" />
          </md-field>

          <md-field md-clearable>
            <label>Telefonnummer des Kontakts</label>
            <md-input type="text" v-model="filters.phone_number" />
          </md-field>

          <md-checkbox v-model="filters.has_open_tasks"
            >Nur Kontakte mit offenen Aufgaben anzeigen</md-checkbox
          >

          <md-field>
            <label>Status des Kontakts</label>
            <md-select v-model="filters.status">
              <md-option value="all">Alle Kontakte</md-option>
              <md-option value="backlog">Nicht bearbeitet</md-option>
              <md-option value="in_progress">In Bearbeitung</md-option>
              <md-option value="in_review">In Überprüfung</md-option>
              <md-option value="finished">Abgeschlossen</md-option>
              <md-option value="on_hold">Pausiert</md-option>
              <md-option value="retired">Ausgeschieden</md-option>
            </md-select>
          </md-field>

          <!--<md-field>
            <label>Versandstatus des Kontakts</label>
            <md-select v-model="filters.status">
              <md-option value="all">Alle Kontakte</md-option>
              <md-option value="backlog">Nicht bearbeitet</md-option>
              <md-option value="by_mail">Per Email versendet</md-option>
              <md-option value="by_fax">Per Fax versendet</md-option>
              <md-option value="by_post">Per Post versendet</md-option>
              <md-option value="not_required">Kein Versand notwendig</md-option>
            </md-select>
          </md-field>-->

          <md-field>
            <label>Typ des Kontakts</label>
            <md-select v-model="filters.is_doctor">
              <md-option value="all">Alle Kontakte</md-option>
              <md-option :value="true">Doktoren</md-option>
              <md-option :value="false">Fachangestellter</md-option>
            </md-select>
          </md-field>

          <div style="display: flex; flex-direction: row; gap: 1rem">
            <md-datepicker
              v-model="filters.first_contact_from"
              :md-model-type="datePickerType"
            >
              <label>Erster Kontakt von</label>
            </md-datepicker>
            <md-datepicker
              v-model="filters.first_contact_to"
              :md-model-type="datePickerType"
            >
              <label>Erster Kontakt bis</label>
            </md-datepicker>
          </div>

          <div style="display: flex; flex-direction: row; gap: 1rem">
            <md-datepicker
              v-model="filters.last_contact_from"
              :md-model-type="datePickerType"
            >
              <label>Letzter Kontakt von</label>
            </md-datepicker>
            <md-datepicker
              v-model="filters.last_contact_to"
              :md-model-type="datePickerType"
            >
              <label>Letzter Kontakt bis</label>
            </md-datepicker>
          </div>

          <md-field>
            <label>Zuordnung des Kontakts</label>
            <md-select v-model="filters.label">
              <md-option value="all">Alle Kontakte</md-option>
              <md-option value="none">Nicht zugeordnet</md-option>
              <md-option v-for="user in users" :key="user.id" :value="user.id">
                {{ user.first_name }} {{ user.last_name }}
              </md-option>
            </md-select>
          </md-field>

          <md-field md-clearable>
            <label>Postleitzahl des Kontakts</label>
            <md-input v-model="filters.postal_code" />
          </md-field>

          <md-field md-clearable>
            <label>Stadt des Kontakts</label>
            <md-input v-model="filters.city" />
          </md-field>

          <md-field md-clearable>
            <label>Fachgruppe des Kontakts</label>
            <md-input v-model="filters.professional_group" />
          </md-field>

          <md-field md-clearable>
            <label>Fachrichtung des Kontakts</label>
            <md-input v-model="filters.specialty" />
          </md-field>

          <md-field md-clearable>
            <label>Institution des Kontakts</label>
            <md-input v-model="filters.institution" />
          </md-field>

          <!--<md-field md-clearable>
            <label>Gemeinschaftspraxis</label>
            <md-input v-model="filters.joint_practice" />
          </md-field>-->
        </md-dialog-content>

        <md-dialog-actions>
          <md-button
            class="md-accent"
            style="margin-right: auto"
            @click="toggleFiltersDialog"
          >
            Schließen
          </md-button>
          <md-button class="md-primary" @click="handleFilterContacts">
            Übersicht filtern
          </md-button>
        </md-dialog-actions>
      </md-dialog>
    </div>

    <create-contact-dialog
      :project_id="projectId"
      :show-create-dialog="dialogs.contact.active"
      @success="handleCreateSingleContact"
      @error="toggleError"
      @closeDialog="toggleContactCreateDialog"
    />

    <md-dialog-alert
      :md-active.sync="showBlockingMessage"
      md-title="Kontakt ist geblockt!"
      md-content="Der Kontakt wird gerade durch eine andere Person bearbeitet!"
      md-confirm-text="Verstanden"
    />
  </iq-main>
</template>

<script>
import loadingMixin from "@/mixins/loadingMixin";
import errorMixin from "@/mixins/errorMixin";
import successMixin from "@/mixins/successMixin";
import routingMixin from "@/mixins/routingMixin";
import dialogMixin from "@/mixins/dialogMixin";
import accessControlMixin from "@/mixins/accessControlMixin";
import createContactDialog from "@/components/contacts/createContactDialog";
import {
  getContactsCountWithFilter,
  getContactsWithFilter,
} from "@/services/api/contacts";
import { createBlocking, getBlocking } from "@/services/api/blocking";
import {
  createContactAttributeBulk,
  deleteContact,
  updateContact,
  bulkUploadContacts,
} from "@/services/api/contact";
import { getProject, getProjectUsers } from "@/services/api/project";
import { getAllContactsExport } from '@/services/api/export';

const toLower = (term) => {
  if (!term) return "";
  return term.toLowerCase();
};

const searchContacts = (items, term) => {
  if (term) {
    return items.filter((item) => {
      return (
        toLower(item.ref_id).includes(toLower(term)) ||
        toLower(item.first_name + " " + item.last_name).includes(toLower(term))
      );
    });
  }
  return items;
};

const removeNotAppliedFilters = (filters) => {
  return Object.fromEntries(
    Object.entries(filters).filter(([key, value]) => {
      if (
        [
          "last_contact_from",
          "last_contact_to",
          "first_contact_from",
          "first_contact_to",
        ].includes(key)
      ) {
        return value;
      }
      return value && value !== "all";
    })
  );
};

const DEFAULT_FILTERS = {
  name: "all",
  status: "all",
  is_doctor: "all",
  label: "all",
  postal_code: "all",
  city: "all",
  specialty: "all",
  professional_group: "all",
  institution: "all",
  last_contact_from: "",
  last_contact_to: "",
  first_contact_from: "",
  first_contact_to: "",
  has_open_tasks: false,
  phone_number: "all",
  //shipping_status: "all",
  //joint_practice: "all",
};

const COLUMN_VISIBILITY_KEY = "columnVisibility";

export default {
  name: "ContactsNew",
  components: {
    createContactDialog,
  },
  mixins: [
    loadingMixin,
    errorMixin,
    successMixin,
    routingMixin,
    dialogMixin,
    accessControlMixin,
  ],
  data() {
    return {
      showBlockingMessage: false,
      isPageLoading: true,
      isExportingData: false,
      projectId: null,
      search: "",
      status: "",
      dialogs: {
        pages: {
          active: false,
          selectedPage: null,
        },
        contact: {
          active: false,
        },
        status: {
          active: false,
          selectedStatus: "in_progress",
        },
        /*shippingStatus: {
          active: false, 
          selectedShippingStatus: null,
        },*/
        attribute: {
          active: false,
          key: "",
          value: "",
          type: "string",
        },
        label: {
          active: false,
          userId: "",
        },
        columns: {
          active: false,
        },
        upload: {
          active: false,
          loading: false,
          excel: {
            result: null,
            name: "Keine Datei ausgewählt",
          },
        },
        filters: {
          active: false,
        },
      },
      filters: {
        name: "all",
        ref_id: "all",
        status: "all",
        is_doctor: "all",
        label: "all",
        postal_code: "all",
        city: "all",
        specialty: "all",
        professional_group: "all",
        institution: "all",
        last_contact_from: "",
        last_contact_to: "",
        first_contact_from: "",
        first_contact_to: "",
        has_open_tasks: false,
        phone_number: "all",
        //shipping_status: "all",
        //joint_practice: "all",
      },
      users: [],
      project: {},
      contacts: {
        count: 0,
        resources: [],
        searched: [],
        selected: [],
      },
      table: {
        limit: 50,
        offset: 0,
        currentSort: "one_key_id",
        currentSortOrder: "asc",
        pages: [],
        columns: {
          isOneKeyIdVisible: true,
          isRefIdVisible: true,
          isSalutationVisible: true,
          isTitleVisible: true,
          isFirstNameVisible: true,
          isLastNameVisible: true,
          // isFullNameVisible: true,
          isStatusVisible: true,
          isTypeVisible: false,
          isSpecialityVisible: false,
          isLabelVisible: false,
          isPhoneNumberVisible: true,
          isMailVisible: false,
          isFaxNumberVisible: false,
          isLastContactVisible: true,
          isFirstContactVisible: false,
          isAttributesVisible: false,
          isPostalCodeVisible: false,
          isCityVisible: false,
          isProfessionalGroupVisible: false,
          isInstitutionVisible: false,
          isEditedByVisible: false,
          isOpenTasksVisible: true,
          isJointPracticeVisible: false,
          //isShippingStatusVisible: false,
          isGeographyVisible: false,
          isRegionVisible: false,
          isSegmentVisible: false,
        },
      },
    };
  },
  computed: {
    datePickerType() {
      return String;
    },
  },
  filters: {
    checkTitle(v) {
      if (!v) return "";
      return `${v} `;
    },
    emptyValue(v) {
      if (!v) return "Keine Angabe";
      return v;
    },
    formatJointPractice(v) {
      if (!v) return "Nein";
      return `Ja (${v})`;
    },
    formatDate(value) {
      if (!value) return "Keine Angabe";
      const parsed = new Date(Date.parse(value));
      return new Intl.DateTimeFormat("de-DE").format(parsed);
    },
    formatTasks(value) {
      if (!Array.isArray(value)) return 0;
      return value.filter((i) => i.is_completed === false).length;
    },
  },
  watch: {
    search: {
      handler(val) {
        this.contacts.searched = searchContacts(this.contacts.resources, val);
      },
    },
    table: {
      handler(val) {
        if (
          typeof val === "object" &&
          "currentSort" in val &&
          "currentSortOrder" in val
        ) {
          localStorage.setItem("currentSort", val.currentSort);
          localStorage.setItem("currentSortOrder", val.currentSortOrder);
        }
      },
      deep: true,
    },
  },
  async created() {
    this.projectId = this.$route.params.id;
    const lastProjectId = localStorage.getItem("lastProjectId");
    const currentSort = localStorage.getItem("currentSort");
    const currentSortOrder = localStorage.getItem("currentSortOrder");
    const currentFilters = localStorage.getItem("filters");
    const currentOffset = localStorage.getItem("currentOffset");

    if (currentSort) {
      this.table.currentSort = currentSort;
    }

    if (currentSortOrder) {
      this.table.currentSortOrder = currentSortOrder;
    }

    if (currentFilters && this.projectId === lastProjectId) {
      this.filters = JSON.parse(currentFilters);
    } else {
      localStorage.setItem("filters", JSON.stringify(DEFAULT_FILTERS));
    }

    if (currentOffset && this.projectId === lastProjectId) {
      this.table.offset = parseInt(currentOffset);
    } else {
      localStorage.setItem("currentOffset", `${0}`)
    }

    if (this.projectId !== lastProjectId) {
      localStorage.setItem("lastProjectId", this.projectId);
    }

    const columnVisibility = localStorage.getItem(COLUMN_VISIBILITY_KEY);

    if (columnVisibility) {
      this.table.columns = JSON.parse(columnVisibility);
    }

    await this.fetchContactsCount();
    await this.fetchContacts();
    await this.fetchUsers();
    await this.fetchProject();

    this.isPageLoading = false;
  },
  methods: {
    // Export
    getFileName() {
      const projectName = this.project.name.replace(/\s/g, '-');
      const now = new Date(Date.now()).toLocaleDateString('de-DE').replace(/\./g, '-');

      return `contacts_export_${projectName}_${now}.xlsx`
    },
    async exportAllContacts() {
      this.isExportingData = true;
      try {
        const response = await getAllContactsExport(this.projectId);
        // eslint-disable-next-line no-undef
        const worksheet = XLSX.utils.json_to_sheet(response.data, { nullError: true });
        // eslint-disable-next-line no-undef
        const workbook = XLSX.utils.book_new();

        // eslint-disable-next-line no-undef
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Contacts');
        // eslint-disable-next-line no-undef
        XLSX.writeFile(workbook, this.getFileName(), { compression: true })
      } catch (e) {
        this.toggleError(
          "Ein Fehler ist aufgetreten. Kontakte konnten nicht exportiert werden!"
        );
        console.error(e);
      }
      this.isExportingData = false;
    },
    // Resolvers
    resolveAttributeValue(value) {
      if (value === "yes") return "Ja";
      if (value === "no") return "Nein";
      return value;
    },
    // Search
    handleResetSearch() {
      this.search = "";
    },
    // Resolvers
    getUserName(id) {
      const user = this.users.find((user) => user.id === id);
      return user ? `${user.first_name} ${user.last_name}` : "Keine Angabe";
    },
    // Dialogs
    toggleStatusDialog() {
      this.dialogs.status.active = !this.dialogs.status.active;
    },
    // toggleShippingStatusDialog() {
    //   this.dialogs.shippingStatus.active = !this.dialogs.shippingStatus.active;
    // },
    togglePageDialog() {
      this.dialogs.pages.active = !this.dialogs.pages.active;
    },
    toggleContactCreateDialog() {
      this.dialogs.contact.active = !this.dialogs.contact.active;
    },
    toggleAttributeDialog() {
      this.dialogs.attribute.active = !this.dialogs.attribute.active;
    },
    toggleLabelDialog() {
      this.dialogs.label.active = !this.dialogs.label.active;
    },
    toggleColumnsDialog() {
      if (this.dialogs.columns.active) {
        localStorage.setItem(
          COLUMN_VISIBILITY_KEY,
          JSON.stringify(this.table.columns)
        );
      }
      this.dialogs.columns.active = !this.dialogs.columns.active;
    },
    toggleUploadDialog() {
      this.dialogs.upload.active = !this.dialogs.upload.active;
    },
    toggleFiltersDialog() {
      this.dialogs.filters.active = !this.dialogs.filters.active;
    },
    // Fetching
    async fetchContactsCount() {
      try {
        const filters = removeNotAppliedFilters(this.filters);
        const res = await getContactsCountWithFilter(this.projectId, filters);
        this.contacts.count = res.data.count;
        let pages = [];
        for (
          let i = 1;
          i <= Math.ceil(res.data.count / this.table.limit);
          i++
        ) {
          pages.push(i);
        }
        this.table.pages = pages;
      } catch (e) {
        console.error(e);
        this.toggleError(
          "Ein Fehler ist aufgetreten. Versuchen Sie es später noch einmal!"
        );
      }
    },
    async fetchProject() {
      try {
        this.project = await getProject(this.projectId);
      } catch (e) {
        console.error(e);
      }
    },
    async fetchContacts() {
      try {
        const filters = removeNotAppliedFilters(this.filters);
        const res = await getContactsWithFilter(
          this.projectId,
          filters,
          this.table.limit,
          this.table.offset
        );
        this.contacts.resources = res.data;
        this.contacts.searched = res.data;
      } catch (e) {
        console.error(e);
        this.toggleError(
          "Ein Fehler ist aufgetreten. Versuchen Sie es später noch einmal!"
        );
      }
    },
    async fetchUsers() {
      try {
        const res = await getProjectUsers(this.projectId);
        this.users = res;
      } catch (e) {
        console.error(e);
        this.toggleError(
          "Ein Fehler ist aufgetreten. Versuchen Sie es später noch einmal!"
        );
      }
    },
    async handleCreateSingleContact() {
      this.toggleSuccess("Kontakt wurde erfolgreich erstellt!");
      await this.reFetchPage();
    },
    // Table
    customSortFn(values) {
      const sortBy = this.table.currentSort;
      const sortOrder = this.table.currentSortOrder === "desc" ? -1 : 1;

      return values.sort((a, b) => {
        if (!a[sortBy] || !b[sortBy]) {
          return false;
        }

        if (typeof a[sortBy] === "boolean" && typeof b[sortBy] === "boolean") {
          return (a[sortBy] - b[sortBy]) * sortOrder;
        }

        if (sortBy === "first_name") {
          const nameA = `${a["first_name"]} ${a["last_name"]}`;
          const nameB = `${b["first_name"]} ${b["last_name"]}`;
          return nameA.localeCompare(nameB) * sortOrder;
        }

        if (sortBy === "ref_id") {
          const aVal = !isNaN(a[sortBy]) ? parseFloat(a[sortBy]) : a[sortBy];
          const bVal = !isNaN(b[sortBy]) ? parseFloat(b[sortBy]) : b[sortBy];
          return (aVal - bVal) * sortOrder;
        }

        return a[sortBy].localeCompare(b[sortBy]) * sortOrder;
      });
    },
    handleTableSelection(items) {
      this.contacts.selected = items;
    },
    // Redirection and blocking
    async isContactBlocked(id) {
      let response = await getBlocking(id);
      return response.data.is_blocked;
    },
    async handleRedirectRequest(id) {
      try {
        let isBlocked = await this.isContactBlocked(id);
        if (isBlocked) {
          this.toggleBlockingDialog();
        } else {
          await createBlocking(id);
          await this.redirect({
            name: "Contact",
            params: { id: this.projectId, contact_id: id },
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    // Bulk uploading
    triggerFileUpload() {
      this.$refs.fileInput.click();
    },
    onUpload(e) {
      this.dialogs.upload.excel.name = e.target.files[0].name;
      let reader = new FileReader();

      reader.addEventListener(
        "load",
        function () {
          // eslint-disable-next-line no-undef
          let workbook = XLSX.read(reader.result, { type: "binary" });

          workbook.SheetNames.forEach((sheet) => {
            this.dialogs.upload.excel.result =
              XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet]); // eslint-disable-line no-undef
          });
        }.bind(this),
        false
      );

      reader.readAsBinaryString(e.target.files[0]);
    },
    async handleBulkUpload() {
      this.dialogs.upload.loading = true;

      try {
        await bulkUploadContacts(
          this.projectId,
          this.dialogs.upload.excel.result
        );
      } catch (e) {
        console.error(e);
        this.toggleError(
          "Leider ist beim Hochladen der Kontakte etwas schief gegangen!"
        );
      }

      await this.reFetchPage();
      this.toggleUploadDialog();
      this.toggleSuccess("Kontakte wurden erfolgreich hochgeladen!");

      this.dialogs.upload.loading = false;
    },
    // Pagination
    getTotalPagesCount() {
      return Math.ceil(this.contacts.count / this.table.limit);
    },
    async handleSelectPage() {
      if (this.dialogs.pages.selectedPage > 0) {
        this.table.offset = this.dialogs.pages.selectedPage - 1;
        localStorage.setItem("currentOffset", `${this.dialogs.pages.selectedPage - 1}`);
        this.togglePageDialog();
        this.isPageLoading = true;
        await this.fetchContacts();
        this.isPageLoading = false;
      }
    },
    async handlePageUp() {
      if (this.table.offset < this.getTotalPagesCount()) {
        const newOffset = this.table.offset + 1;
        this.table.offset = newOffset;
        localStorage.setItem("currentOffset", `${newOffset}`);
        this.isPageLoading = true;
        await this.fetchContacts();
        this.isPageLoading = false;
      }
    },
    async handlePageDown() {
      if (this.table.offset > 0) {
        const newOffset = this.table.offset - 1;
        this.table.offset = newOffset;
        localStorage.setItem("currentOffset", `${newOffset}`);
        this.isPageLoading = true;
        await this.fetchContacts();
        this.isPageLoading = false;
      }
    },
    // Table fns
    async reFetchPage() {
      await this.fetchContactsCount();
      await this.fetchContacts();
      window.location.reload();
      this.forceUpdate = this.forceUpdate + 1;
    },
    async handleDeleteContacts() {
      if (!this.contacts.selected || this.contacts.selected.length === 0) {
        return;
      }

      for (let contact of this.contacts.selected) {
        try {
          await deleteContact(contact.id);
        } catch (e) {
          console.error(e);
        }
      }

      await this.reFetchPage();

      this.toggleSuccess("Kotakte wurden gelöscht");
    },
    async handleChangeStatus() {
      if (!this.contacts.selected || this.contacts.selected.length === 0) {
        return;
      }

      for (let contact of this.contacts.selected) {
        try {
          await updateContact(contact.id, {
            status: this.dialogs.status.selectedStatus,
          });
        } catch (e) {
          console.error(e);
        }
      }

      await this.reFetchPage();

      this.toggleStatusDialog();
      this.toggleSuccess("Status wurde aktualisiert");
    },
    /*async handleChangeShippingStatus() {
      if (!this.contacts.selected || this.contacts.selected.length === 0) {
        return;
      }

      for (let contact of this.contacts.selected) {
        try {
          await updateContact(contact.id, {
            shippingStatus: this.dialogs.shippingStatus.selectedShippingStatus,
          });
        } catch (e) {
          console.error(e);
        }
      }

      await this.reFetchPage();

      this.toggleShippingStatusDialog();
      this.toggleSuccess("Versandstatus wurde aktualisiert");
    },*/
    async handleCreateAttribute() {
      if (!this.contacts.selected || this.contacts.selected.length === 0) {
        return;
      }

      const ids = this.contacts.selected.map((c) => c.id);
      const attr = {
        attribute_key: this.dialogs.attribute.key,
        attribute_value: this.dialogs.attribute.value,
        attribute_type: this.dialogs.attribute.type,
      };

      try {
        await createContactAttributeBulk(this.projectId, { ...attr, ids });
      } catch (e) {
        console.error(e);
      }

      await this.reFetchPage();

      this.toggleAttributeDialog();
      this.toggleSuccess("Attribute wurde hinzugefügt");
    },
    async handleAssignContact() {
      if (!this.contacts.selected || this.contacts.selected.length === 0) {
        return;
      }

      for (let contact of this.contacts.selected) {
        try {
          await updateContact(contact.id, { label: this.dialogs.label.userId });
        } catch (e) {
          console.error(e);
        }
      }

      await this.reFetchPage();

      this.toggleLabelDialog();
      this.toggleSuccess("Kontakte wurden zugeordnet");
    },
    async handleUnassignContact() {
      if (!this.contacts.selected || this.contacts.selected.length === 0) {
        return;
      }

      for (let contact of this.contacts.selected) {
        try {
          await updateContact(contact.id, { label: "" });
        } catch (e) {
          console.error(e);
        }
      }

      await this.reFetchPage();

      this.toggleLabelDialog();
      this.toggleSuccess("Zuordnung wurde gelöscht");
    },
    async handleAssignAsJointPractice() {
      if (!this.contacts.selected || !this.contacts.selected.length) {
        return;
      }

      const randomId = Math.floor(Math.random() * Math.pow(10, 15)).toString(
        16
      );

      for (const contact of this.contacts.selected) {
        try {
          await updateContact(contact.id, { joint_practice: randomId });
        } catch (e) {
          console.error(e);
        }
      }

      await this.reFetchPage();
      this.toggleSuccess("Kontakte wurden als Gemeinschaftspraxis zugeordnet");
    },
    async handleDeassignAsJointPractice() {
      if (!this.contacts.selected || !this.contacts.selected.length) {
        return;
      }

      for (const contact of this.contacts.selected) {
        try {
          await updateContact(contact.id, { joint_practice: null });
        } catch (e) {
          console.error(e);
        }
      }

      await this.reFetchPage();
      this.toggleSuccess("Kontakte wurden als Gemeinschaftspraxis entfernt");
    },
    // Filtering
    async handleResetFilter(id) {
      if (id === "last_contact") {
        this.filters.last_contact_from = "";
        this.filters.last_contact_to = "";
      } else if (id === "first_contact") {
        this.filters.first_contact_from = "";
        this.filters.first_contact_to = "";
      } else if (id === "has_open_tasks") {
        this.filters.has_open_tasks = false;
      } else {
        this.filters[id] = "all";
      }

      localStorage.setItem("filters", JSON.stringify(this.filters));

      await this.fetchContactsCount();
      await this.fetchContacts();
    },
    async handleFilterContacts() {
      this.isPageLoading = true;
      this.table.offset = 0;

      localStorage.setItem("filters", JSON.stringify(this.filters));

      await this.fetchContactsCount();
      await this.fetchContacts();

      this.toggleFiltersDialog();
      this.isPageLoading = false;
    },
    toggleBlockingDialog() {
      this.showBlockingMessage = !this.showBlockingMessage;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/css/main";
@import "../../assets/css/constants";
@import "../../assets/css/mixins";

.autocomplete-adjuster {
  & > input {
    max-width: 150px !important;
  }
}

// Search bar
.search-bar {
  padding-left: 0.5rem;
  min-height: 2.5rem;
  min-width: 600px;

  @include searchBarOutline;
}

.search-wrapper {
  position: relative;

  & > .md-button {
    position: absolute;
    border-radius: $border-radius;
    right: -0.3rem;
  }
}

// Content
.content-container {
  overflow: auto;
}

// Table
.table-pagination {
  height: 56px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  background-color: white;

  .table-pagination-previous {
    margin-right: 2px;
    margin-left: 18px;
  }
}

// Bulk uploading
.upload-container {
  padding: $padding;
  transition: $transition;
  border: 1px dashed var(--iq-blue-500);
  text-align: center;
  position: relative;

  &-disabled {
    filter: opacity(0.25);
    pointer-events: none;
  }
}

.explainer-container {
  padding: $padding $padding 0 $padding;
  text-align: center;
}

.chip-adjuster {
  height: 24px;
  line-height: 24px;
  color: #525252;
}
</style>
